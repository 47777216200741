'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';
import { adaptToGenericMessage, approveEIP155Request } from '@/lib/web3-access';
import { useDappState } from '@/features/dapp-iframe/context';
import { CommunicatorMessages } from '@/features/dapp-iframe/hooks';
import { RequestDataCard, MemoDropdown } from '@/features/web3-modals';
import { DappIframeModalWrapper } from '@/features/web3-modals/dapp-iframe';

import styles from './SendTransactionModal.module.scss';

export function SendTransactionModal() {
  const t = useTranslations('Components.Web3Modals');

  const { modal, mutations, settings } = useDappState();

  const requestId = modal.data?.requestId!;
  const requestParams = modal.data?.requestParams!;
  const sessionMetadata = modal.data?.sessionMetadata!;
  const communicator = modal.data?.communicator;

  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestParams) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);
        const response = await approveEIP155Request({
          requestParams,
          sessionMetadata,
          data: {
            vaultId: settings?.vaultId,
            memo,
          },
        });

        if ('error' in response) throw new Error(response.error.message);

        communicator?.send({ safeTxHash: response.result, txHash: response.result }, requestId);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
        communicator?.send(CommunicatorMessages.ERROR_TRANSACTION_MESSAGE, requestId, true);
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [requestParams, sessionMetadata, mutations, modal.data, settings?.vaultId, memo, communicator, requestId]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestParams) {
      setIsLoadingReject(true);
      try {
        communicator?.send(CommunicatorMessages.REJECT_TRANSACTION_MESSAGE, requestId, true);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestParams, communicator, requestId, mutations]);

  // Ensure request and session metadata are defined
  if (!requestParams || !sessionMetadata) {
    return <div className={styles.text}>{t('Common.requestNotFound')}</div>;
  }

  const genericMessage = adaptToGenericMessage(requestParams.method, requestParams.params, {
    chainId: requestParams.chainId,
    dappName: sessionMetadata.name,
    dappSite: sessionMetadata.url ? new URL(sessionMetadata.url).hostname : '',
    // FIXME: replace with vault name
    vaultName: settings?.vaultId,
    ...(settings.user?.name && { userName: settings.user.name }),
    memo,
  });

  return (
    <DappIframeModalWrapper
      intention={t('SessionTypes.SendTransaction.intention')}
      metadata={sessionMetadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      <RequestDataCard message={genericMessage} />
      <MemoDropdown memo={memo} onMemoChange={setMemo} />
    </DappIframeModalWrapper>
  );
}
