'use client';

import { useCallback } from 'react';

import { useLocalStorage } from '@/lib/hooks/browser';
import { trimTrailingSlash, DAPP_NAMESPACE } from '@/features/dapp-iframe/utils';
import { AllowedFeatures, PermissionStatus } from '@/features/dapp-iframe/types';

const BROWSER_PERMISSIONS = `${DAPP_NAMESPACE}.BROWSER_PERMISSIONS`;

export type BrowserPermission = { feature: AllowedFeatures; status: PermissionStatus };

type BrowserPermissions = { [origin: string]: BrowserPermission[] };

type BrowserPermissionChangeSet = { feature: AllowedFeatures; selected: boolean }[];

type UseBrowserPermissionsReturnType = {
  permissions: BrowserPermissions;
  getPermissions: (origin: string) => BrowserPermission[];
  updatePermission: (origin: string, changeset: BrowserPermissionChangeSet) => void;
  addPermissions: (origin: string, permissions: BrowserPermission[]) => void;
  removePermissions: (origin: string) => void;
  getAllowedFeaturesList: (origin: string) => string;
};

export function useBrowserPermissions(): UseBrowserPermissionsReturnType {
  const [permissions, setPermissions] = useLocalStorage<BrowserPermissions>(BROWSER_PERMISSIONS, {});

  const getPermissions = useCallback(
    (origin: string) => {
      return permissions[trimTrailingSlash(origin)] || [];
    },
    [permissions],
  );

  const updatePermission = useCallback(
    (origin: string, changeset: BrowserPermissionChangeSet) => {
      const appUrl = trimTrailingSlash(origin);

      setPermissions({
        ...permissions,
        [appUrl]: permissions[appUrl].map((p) => {
          const change = changeset.find((change) => change.feature === p.feature);

          if (change) {
            p.status = change.selected ? PermissionStatus.GRANTED : PermissionStatus.DENIED;
          }

          return p;
        }),
      });
    },
    [permissions, setPermissions],
  );

  const removePermissions = useCallback(
    (origin: string) => {
      delete permissions[trimTrailingSlash(origin)];
      setPermissions({ ...permissions });
    },
    [permissions, setPermissions],
  );

  const addPermissions = useCallback(
    (origin: string, selectedPermissions: BrowserPermission[]) => {
      setPermissions({ ...permissions, [trimTrailingSlash(origin)]: selectedPermissions });
    },
    [permissions, setPermissions],
  );

  const getAllowedFeaturesList = useCallback(
    (origin: string): string => {
      return getPermissions(origin)
        .filter(({ status }) => status === PermissionStatus.GRANTED)
        .map((permission) => permission.feature)
        .join('; ');
    },
    [getPermissions],
  );

  return {
    permissions,
    getPermissions,
    updatePermission,
    addPermissions,
    removePermissions,
    getAllowedFeaturesList,
  };
}
