'use client';

import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { TransactionDetails } from '@safe-global/safe-gateway-typescript-sdk';
import type {
  BaseTransaction,
  EIP712TypedData,
  EnvironmentInfo,
  GetTxBySafeTxHashParams,
  RequestId,
  RPCPayload,
  SendTransactionRequestParams,
  SendTransactionsParams,
  SignMessageParams,
  SignTypedMessageParams,
  SafeSettings,
} from '@safe-global/safe-apps-sdk';
import { Methods, RPC_CALLS } from '@safe-global/safe-apps-sdk';
import type { Permission, PermissionRequest } from '@safe-global/safe-apps-sdk/dist/types/types/permissions';
import { getAddress } from 'ethers';

import { EIP155Chain, createWeb3Provider } from '@/lib/web3-access';
import { globalLogger } from '@/lib/logger';
import { AppCommunicator } from '@/features/dapp-iframe/services';
import { CustomBaseTransaction, CustomSafeInfo } from '@/features/dapp-iframe/types';
import { DappPermissionsRequest, useDappState } from '@/features/dapp-iframe/context';
import { Dapp } from '@/features/dapps/types';

export enum CommunicatorMessages {
  ERROR_TRANSACTION_MESSAGE = 'Something went wrong',
  REJECT_TRANSACTION_MESSAGE = 'Transaction was rejected',
}

type JsonRpcResponse = {
  jsonrpc: string;
  id: number;
  result?: any;
  error?: string;
};

const logger = globalLogger.child({ scope: 'dapp-iframe' });

export type UseAppCommunicatorHandlers = {
  onConfirmTransactions: (txs: BaseTransaction[], requestId: RequestId, params?: SendTransactionRequestParams) => void;
  onSignMessage: (
    message: string | EIP712TypedData,
    requestId: string,
    method: Methods.signMessage | Methods.signTypedMessage,
    sdkVersion: string,
  ) => void;
  onGetTxBySafeTxHash: (transactionId: string) => Promise<TransactionDetails>;
  onGetEnvironmentInfo: () => EnvironmentInfo;
  onGetSafeInfo: () => CustomSafeInfo;
  onGetChainInfo: () => EIP155Chain | null;
  onGetPermissions: (origin: string) => Permission[];
  onSetPermissions: (permissionsRequest?: DappPermissionsRequest) => void;
  onSetSafeSettings: (settings: SafeSettings) => SafeSettings;
};

export function useAppCommunicator(
  iframeRef: MutableRefObject<HTMLIFrameElement | null>,
  handlers: UseAppCommunicatorHandlers,
): AppCommunicator | undefined {
  const [communicator, setCommunicator] = useState<AppCommunicator | undefined>();
  const { settings } = useDappState();

  const dappWebProvider = useMemo(() => {
    if (!settings.currentChain) return;

    return createWeb3Provider(settings.currentChain);
  }, [settings.currentChain]);

  useEffect(() => {
    let communicatorInstance: AppCommunicator;

    const initCommunicator = (iframeRef: MutableRefObject<HTMLIFrameElement | null>, app?: Dapp) => {
      communicatorInstance = new AppCommunicator(iframeRef, {
        onMessage: (msg) => {
          if (!msg.data) return;
          logger.info('message received', {
            method: msg.data.method,
            ethMethod: (msg.data.params as any)?.call,
            version: msg.data.env?.sdkVersion,
            appName: app?.name ?? app?.homepage,
          });
        },
        onError: (error) => {
          logger.error(`[useAppCommunicator] onError: ${(error as Error).message}`);
        },
      });

      setCommunicator(communicatorInstance);
    };

    initCommunicator(iframeRef, settings.dAppInfo);

    return () => {
      communicatorInstance?.clear();
    };
  }, [iframeRef, settings.dAppInfo]);

  // Adding communicator logic for the required SDK Methods
  // We don't need to unsubscribe from the events because there can be just one subscription
  // per event type and the next effect run will simply replace the handlers
  useEffect(() => {
    communicator?.on(Methods.getTxBySafeTxHash, (msg) => {
      const { safeTxHash } = msg.data.params as GetTxBySafeTxHashParams;

      return handlers.onGetTxBySafeTxHash(safeTxHash);
    });

    communicator?.on(Methods.getEnvironmentInfo, handlers.onGetEnvironmentInfo);

    communicator?.on(Methods.getSafeInfo, handlers.onGetSafeInfo);

    communicator?.on(Methods.rpcCall, async (msg) => {
      logger.info('[useAppCommunicator] rpcCall', msg);
      const params = msg.data.params as RPCPayload;

      if (params.call === RPC_CALLS.safe_setSettings) {
        const settings = params.params[0] as SafeSettings;
        return handlers.onSetSafeSettings(settings);
      }

      if (!dappWebProvider) {
        throw new Error('dappWebProvider is not initialized');
      }

      try {
        return await dappWebProvider.send(params.call, params.params);
      } catch (err) {
        throw new Error((err as JsonRpcResponse).error);
      }
    });

    communicator?.on(Methods.sendTransactions, (msg) => {
      const { txs, params } = msg.data.params as SendTransactionsParams;

      const transactions = txs.map((tx) => {
        // gas is passed by some dapps
        const { to, value, gas, data } = tx as CustomBaseTransaction;
        return {
          to: getAddress(to),
          value: value ? BigInt(value).toString() : '0',
          data: data || '0x',
          ...(gas && { gas: BigInt(gas) }),
        };
      });

      handlers.onConfirmTransactions(transactions, msg.data.id, params);
    });

    communicator?.on(Methods.signMessage, (msg) => {
      const { message } = msg.data.params as SignMessageParams;
      const sdkVersion = msg.data.env.sdkVersion;
      handlers.onSignMessage(message, msg.data.id, Methods.signMessage, sdkVersion);
    });

    communicator?.on(Methods.signTypedMessage, (msg) => {
      const { typedData } = msg.data.params as SignTypedMessageParams;
      const sdkVersion = msg.data.env.sdkVersion;
      handlers.onSignMessage(typedData, msg.data.id, Methods.signTypedMessage, sdkVersion);
    });

    communicator?.on(Methods.getChainInfo, handlers.onGetChainInfo);

    communicator?.on(Methods.wallet_getPermissions, (msg) => {
      return handlers.onGetPermissions(msg.origin);
    });

    communicator?.on(Methods.wallet_requestPermissions, (msg) => {
      handlers.onSetPermissions({
        origin: msg.origin,
        request: msg.data.params as PermissionRequest[],
        requestId: msg.data.id,
      });
    });
  }, [dappWebProvider, handlers, communicator]);

  return communicator;
}
