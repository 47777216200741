'use client';
import { ReactNode } from 'react';

import { useDappState } from '@/features/dapp-iframe/context';
import { VerifyInfoBox, ProjectInfoCard, ModalFooter } from '@/features/web3-modals';
import type { LoaderProps } from '@/features/web3-modals';
import { SessionMetadata } from '@/lib/web3-access';
import { ModalDialog } from '@/components/ModalDialog';

import styles from './ModalWrapper.module.scss';

type Props = {
  children: ReactNode;
  metadata: SessionMetadata;
  onApprove: () => void;
  onReject: () => void;
  intention?: string;
  infoBoxCondition?: boolean;
  infoBoxText?: string;
  approveLoader?: LoaderProps;
  rejectLoader?: LoaderProps;
  disableApprove?: boolean;
  disableReject?: boolean;
};

export function DappIframeModalWrapper({
  children,
  metadata,
  onApprove,
  onReject,
  approveLoader,
  rejectLoader,
  intention,
  infoBoxCondition,
  infoBoxText,
  disableApprove,
  disableReject,
}: Props) {
  const { modal } = useDappState();

  return (
    <ModalDialog
      isOpen={modal.isOpen}
      preventCancel
      hideCloseButton
      className={styles.modal}
      // Closing is handled by the reducer
      onClose={() => {}}
      footer={
        <ModalFooter
          onApprove={onApprove}
          onReject={onReject}
          approveLoader={approveLoader}
          rejectLoader={rejectLoader}
          infoBoxCondition={infoBoxCondition}
          infoBoxText={infoBoxText}
          disableApprove={disableApprove}
          disableReject={disableReject}
        />
      }
    >
      <ProjectInfoCard metadata={metadata} intention={intention} />
      <div className={styles.divider} />
      <VerifyInfoBox />
      <div className={styles.container}>{children}</div>
    </ModalDialog>
  );
}
