export * from './useBrowserPermissions';

import { capitalize } from '@walletconnect/utils';

import { AllowedFeatures } from '@/features/dapp-iframe/types';

export const getBrowserPermissionDisplayValues = (feature: AllowedFeatures) => {
  return {
    displayName: capitalize(feature).replaceAll('-', ' '),
    description: `Allow to use - ${feature}`,
  };
};
