'use client';

import { useState, useEffect, useRef, useCallback } from 'react';

const SHOW_ALERT_TIMEOUT = 10_000;

const DAPPS_THIRD_PARTY_COOKIES_CHECK_URL = 'https://www.iofinnet.com';

type ThirdPartyCookiesType = {
  thirdPartyCookiesDisabled: boolean;
  setThirdPartyCookiesDisabled: (value: boolean) => void;
};

const isSafari = (): boolean => {
  return navigator.userAgent.includes('Safari') && navigator.userAgent.indexOf('Chrome') <= -1;
};

const createIframe = (uri: string, onload: () => void): HTMLIFrameElement => {
  const iframeElement: HTMLIFrameElement = document.createElement('iframe');

  iframeElement.src = uri;
  iframeElement.setAttribute('style', 'display:none');
  iframeElement.addEventListener('load', onload);

  return iframeElement;
};

export function useThirdPartyCookies(): ThirdPartyCookiesType {
  const iframeRef = useRef<HTMLIFrameElement>();
  const [thirdPartyCookiesDisabled, setThirdPartyCookiesDisabled] = useState<boolean>(false);

  const messageHandler = useCallback((event: MessageEvent) => {
    const data = event.data;

    try {
      if (Object.prototype.hasOwnProperty.call(data, 'isCookieEnabled')) {
        setThirdPartyCookiesDisabled(!data.isCookieEnabled);
        window.removeEventListener('message', messageHandler);
        iframeRef.current?.remove();
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (isSafari()) {
      return;
    }

    window.addEventListener('message', messageHandler);

    const iframeElement: HTMLIFrameElement = createIframe(
      DAPPS_THIRD_PARTY_COOKIES_CHECK_URL,
      () => iframeElement?.contentWindow?.postMessage({ test: 'cookie' }, '*'),
    );

    iframeRef.current = iframeElement;
    document.body.append(iframeElement);
  }, [messageHandler]);

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    if (thirdPartyCookiesDisabled) {
      id = setTimeout(() => setThirdPartyCookiesDisabled(false), SHOW_ALERT_TIMEOUT);
    }

    return () => clearTimeout(id);
  }, [thirdPartyCookiesDisabled]);

  return { thirdPartyCookiesDisabled, setThirdPartyCookiesDisabled };
}
