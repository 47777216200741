'use client';

import { useState, type MutableRefObject } from 'react';
import {
  Methods,
  EIP712TypedData,
  RequestId,
  SendTransactionRequestParams,
  SafeSettings,
} from '@safe-global/safe-apps-sdk';
import { getTransactionDetails, TransactionDetails } from '@safe-global/safe-gateway-typescript-sdk';

import type { UseAppCommunicatorHandlers } from '@/features/dapp-iframe/hooks';
import { CommunicatorMessages, useAppCommunicator } from '@/features/dapp-iframe/hooks';
import {
  EIP155_NAMESPACE,
  EIP155_SIGNING_METHODS,
  RequestParams,
  SessionMetadata,
  encodeEIP191Message,
  getSignTypedDataParamsData,
} from '@/lib/web3-access';
import { globalLogger } from '@/lib/logger';
import type { AppCommunicator } from '@/features/dapp-iframe/services';
import { useDappState } from '@/features/dapp-iframe/context';
import { CustomBaseTransaction } from '@/features/dapp-iframe/types';

const logger = globalLogger.child({ scope: 'dapp-iframe' });

export function useCustomAppCommunicator(
  iframeRef: MutableRefObject<HTMLIFrameElement | null>,
  overrideHandlers?: Partial<UseAppCommunicatorHandlers>,
): AppCommunicator | undefined {
  const [safeSettings, setSafeSettings] = useState<SafeSettings>({
    offChainSigning: false,
  });
  const { settings, mutations } = useDappState();

  const createModalData = (requestId: RequestId, method: keyof typeof EIP155_SIGNING_METHODS, params: any[]) => {
    const requestParams: RequestParams = {
      id: Number.parseInt(requestId.replaceAll(/\D/g, '')),
      chainId: `${EIP155_NAMESPACE}:${settings?.currentChain.chainId}`,
      method,
      params,
    };

    const sessionMetadata: SessionMetadata = {
      url: settings?.dAppInfo?.homepage,
      name: settings?.dAppInfo?.name,
      description: settings?.dAppInfo?.description,
      icons: settings?.dAppInfo?.image_url?.md ? [settings.dAppInfo.image_url.md] : undefined,
    };

    return { communicator, requestId, requestParams, sessionMetadata };
  };

  const communicator = useAppCommunicator(iframeRef, {
    onConfirmTransactions: async (
      txs: CustomBaseTransaction[],
      requestId: RequestId,
      params?: SendTransactionRequestParams,
    ) => {
      logger.info('onConfirmTransactions', requestId, txs, params);

      const isMultiSend = txs.length > 1;
      if (isMultiSend) logger.error('MultiSend transactions is not supported yet, only first transaction will be sent');

      const { to, value, data, gas } = txs[0];
      const transaction = {
        from: settings?.address,
        to,
        value,
        data,
        // Prioritize gas from transaction over gas from params
        ...(gas ? { gas: BigInt(gas) } : params?.safeTxGas ? { gas: BigInt(params.safeTxGas) } : {}),
      };

      const modalData = createModalData(requestId, EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION, [transaction]);

      mutations.openModal('SendTransactionModal', modalData);
    },
    onSignMessage: async (
      message: string | EIP712TypedData,
      requestId: string,
      method: Methods.signMessage | Methods.signTypedMessage,
      sdkVersion: string,
    ) => {
      logger.info('onSignMessage', requestId, message, method, sdkVersion);
      try {
        let modalName: 'SignMessageModal' | 'SignTypedDataModal';
        let modalData;

        switch (method) {
          case Methods.signMessage: {
            const encodedMessage = encodeEIP191Message(message as string);
            modalName = 'SignMessageModal';
            modalData = createModalData(requestId, EIP155_SIGNING_METHODS.PERSONAL_SIGN, [encodedMessage]);
            break;
          }
          case Methods.signTypedMessage: {
            const {
              domain,
              types,
              message: typeData,
              primaryType,
            } = getSignTypedDataParamsData([JSON.stringify(message)]);
            modalName = 'SignTypedDataModal';
            modalData = createModalData(requestId, EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4, [
              { domain, types, message: typeData, primaryType },
            ]);
            break;
          }
          default: {
            throw new Error('Invalid method');
          }
        }
        mutations.openModal(modalName, modalData);
      } catch (err) {
        logger.error('[useCustomAppCommunicator] onSignMessage error', (err as Error).message);
        communicator?.send(CommunicatorMessages.ERROR_TRANSACTION_MESSAGE, requestId, true);
      }
    },
    onGetTxBySafeTxHash: (txHash) => {
      logger.info('onGetTxBySafeTxHash', txHash);
      if (!settings?.currentChain) return Promise.resolve({} as TransactionDetails);
      return getTransactionDetails(settings?.currentChain?.chainId.toString(), txHash);
    },
    onGetEnvironmentInfo: () => ({
      origin: document.location.origin,
    }),
    onGetSafeInfo: () => ({
      safeAddress: settings?.address,
      chainId: settings?.currentChain.chainId,
    }),
    onGetChainInfo: () => settings?.currentChain,
    onSetSafeSettings: (settings: SafeSettings) => {
      logger.info('onSetSafeSettings', safeSettings);
      const newSettings: SafeSettings = {
        ...safeSettings,
        offChainSigning: !!settings.offChainSigning,
      };
      setSafeSettings(newSettings);
      return newSettings;
    },
    onGetPermissions: () => [],
    onSetPermissions: () => {},
    ...overrideHandlers,
  });

  return communicator;
}
