'use client';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useSearchParams } from 'next/navigation';

import { EIP155Chain } from '@/lib/web3-access';
import { Network } from '@/lib/models';
import { DappIframeModalProvider } from '@/features/web3-modals/dapp-iframe';
import { getInitialState, initialState } from '@/features/dapp-iframe/utils';
import { Dapp } from '@/features/dapps/types';
import { UserInfo } from '@/lib/auth';

import { State, reducer } from './dapp-state';
type ContextType = State & {
  mutations: {
    setIsLoading: (isLoading: boolean) => void;
    setCurrentChain: (chain: EIP155Chain) => void;
    setChains: (chains: EIP155Chain[]) => void;
    openModal: (view: State['modal']['view'], data: State['modal']['data']) => void;
    closeModal: () => void;
  };
};

const initialContext: ContextType = {
  ...initialState,
  mutations: {
    setIsLoading: () => null,
    setCurrentChain: () => null,
    setChains: () => null,
    openModal: () => null,
    closeModal: () => null,
  },
};

type ProviderProps = {
  children: React.ReactNode;
  user: UserInfo;
  dapp: Dapp;
};

const DappContext = createContext<ContextType>(initialContext);

export const useDappState = () => useContext(DappContext);

export function DappProvider({ children, user, dapp: dAppInfo }: ProviderProps) {
  const searchParams = useSearchParams();
  const address = searchParams.get('address')!;
  const vaultId = searchParams.get('vaultId')!;
  const network = searchParams.get('network') as Network.Type;

  const [state, dispatch] = useReducer(reducer, {
    ...initialContext,
    settings: {
      ...initialContext.settings,
      user,
      vaultId,
      address,
      dAppInfo,
    },
  });

  useEffect(() => {
    const initializeState = async () => {
      const initialState = await getInitialState(network);
      dispatch({
        type: 'initialize',
        payload: {
          ...initialState,
          settings: {
            ...initialState.settings,
            user,
            vaultId,
            address,
            dAppInfo,
          },
        },
      });
      // Only set isLoading to false when we have a default vaultId
      if (vaultId) {
        dispatch({ type: 'set-is-loading', payload: false });
      }
    };

    initializeState();
  }, [address, network, vaultId, user, dAppInfo]);

  const setCurrentChain = useCallback(
    (chain: EIP155Chain) => dispatch({ type: 'set-current-chain', payload: chain }),
    [],
  );
  const setChains = useCallback((chains: EIP155Chain[]) => dispatch({ type: 'set-chains', payload: chains }), []);
  const setIsLoading = useCallback(
    (isLoading: boolean) => dispatch({ type: 'set-is-loading', payload: isLoading }),
    [],
  );

  const openModal = useCallback(
    (view: State['modal']['view'], data: State['modal']['data']) =>
      dispatch({ type: 'open-modal', payload: { view, data } }),
    [],
  );

  const closeModal = useCallback(() => dispatch({ type: 'close-modal' }), []);

  const value = useMemo(
    () => ({
      ...state,
      mutations: {
        setCurrentChain,
        setChains,
        setIsLoading,
        openModal,
        closeModal,
      },
    }),
    [state, setCurrentChain, setChains, setIsLoading, openModal, closeModal],
  );

  return (
    <DappContext.Provider value={value}>
      <DappIframeModalProvider>{children}</DappIframeModalProvider>
    </DappContext.Provider>
  );
}
