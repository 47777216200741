'use client';

import { useEffect, useRef, useState } from 'react';

const APP_LOAD_ERROR_TIMEOUT = 30_000;
const APP_SLOW_LOADING_WARNING_TIMEOUT = 15_000;
const APP_LOAD_ERROR = 'There was an error loading the Dapp. There might be a problem with the Dapp provider.';

type UseAppIsLoadingReturnType = {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  isAppLoading: boolean;
  setIsAppLoading: (isAppLoading: boolean) => void;
  isLoadingSlow: boolean;
};

export function useIsAppLoading(): UseAppIsLoadingReturnType {
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
  const [isLoadingSlow, setIsLoadingSlow] = useState<boolean>(false);
  const [, setAppLoadError] = useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const timer = useRef<number>();
  const errorTimer = useRef<number>();

  useEffect(() => {
    const clearTimeouts = () => {
      clearTimeout(timer.current);
      clearTimeout(errorTimer.current);
    };

    if (isAppLoading) {
      timer.current = window.setTimeout(() => {
        setIsLoadingSlow(true);
      }, APP_SLOW_LOADING_WARNING_TIMEOUT);
      errorTimer.current = window.setTimeout(() => {
        setAppLoadError(() => {
          throw new Error(APP_LOAD_ERROR);
        });
      }, APP_LOAD_ERROR_TIMEOUT);
    } else {
      clearTimeouts();
      setIsLoadingSlow(false);
    }

    return () => {
      clearTimeouts();
    };
  }, [isAppLoading]);

  return {
    iframeRef,
    isAppLoading,
    setIsAppLoading,
    isLoadingSlow,
  };
}
