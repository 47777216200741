'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import { RequestData } from '@iofinnet/io-web3-provider';

import { showDismissableAlertToast } from '@/components/Toasts';
import { approveEIP155Request, getSignParamsMessage } from '@/lib/web3-access';
import { useDappState } from '@/features/dapp-iframe/context';
import { CommunicatorMessages } from '@/features/dapp-iframe/hooks';
import { MemoDropdown } from '@/features/web3-modals';
import { DappIframeModalWrapper } from '@/features/web3-modals/dapp-iframe';

import styles from './SignMessageModal.module.scss';

export function SignMessageModal() {
  const t = useTranslations('Components.Web3Modals');

  const { modal, mutations, settings } = useDappState();

  const requestId = modal.data?.requestId!;
  const requestParams = modal.data?.requestParams!;
  const sessionMetadata = modal.data?.sessionMetadata!;
  const communicator = modal.data?.communicator;

  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestParams) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);

        const data: RequestData = {
          vaultId: settings?.vaultId,
          memo,
        };

        const response = await approveEIP155Request({
          requestParams,
          sessionMetadata,
          data,
        });

        if ('error' in response) throw new Error(response.error.message);

        communicator?.send({ signature: response.result }, requestId);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
        communicator?.send(CommunicatorMessages.ERROR_TRANSACTION_MESSAGE, requestId, true);
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [requestParams, sessionMetadata, mutations, modal.data, settings?.vaultId, memo, communicator, requestId]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestParams) {
      setIsLoadingReject(true);
      try {
        communicator?.send(CommunicatorMessages.REJECT_TRANSACTION_MESSAGE, requestId, true);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestParams, communicator, requestId, mutations]);

  // Ensure request and session metadata are defined
  if (!requestParams || !sessionMetadata) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get message, convert it to UTF8 string if it is valid hex
  const message = getSignParamsMessage(requestParams.params);

  return (
    <DappIframeModalWrapper
      intention={t('SessionTypes.Sign.intention')}
      metadata={sessionMetadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      <div className={styles.row}>
        <div>
          <h5 className={styles.heading}>{t('Common.message')}</h5>
          <p className={cx(styles.text, styles.textLight)} data-testid="request-message-text">
            {message}
          </p>
        </div>
      </div>
      <div className={styles.memo}>
        <MemoDropdown memo={memo} onMemoChange={setMemo} />
      </div>
    </DappIframeModalWrapper>
  );
}
