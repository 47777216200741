import type { RefObject } from 'react';
import type { SDKMessageEvent, MethodToResponse, ErrorResponse, RequestId } from '@safe-global/safe-apps-sdk';
import { getSDKVersion, Methods, MessageFormatter } from '@safe-global/safe-apps-sdk';

import { initializeEIP155Wallet } from '@/lib/web3-access';

type MessageHandler = (
  msg: SDKMessageEvent,
) => void | MethodToResponse[Methods] | ErrorResponse | Promise<MethodToResponse[Methods] | ErrorResponse | void>;

type AppCommunicatorConfig = {
  onMessage?: (msg: SDKMessageEvent) => void;
  onError?: (error: Error, data: any) => void;
};

export class AppCommunicator {
  private iframeRef: RefObject<HTMLIFrameElement | undefined>;
  private handlers = new Map<Methods, MessageHandler>();
  private config: AppCommunicatorConfig;

  constructor(iframeRef: RefObject<HTMLIFrameElement | undefined>, config?: AppCommunicatorConfig) {
    this.iframeRef = iframeRef;
    this.config = config || {};

    initializeEIP155Wallet();

    window.addEventListener('message', this.handleIncomingMessage);
  }

  on = (method: Methods, handler: MessageHandler): void => {
    this.handlers.set(method, handler);
  };

  private isValidMessage = (msg: SDKMessageEvent): boolean => {
    if (!msg.data) return false;
    if ('isCookieEnabled' in msg.data) {
      return true;
    }

    const sentFromIframe = this.iframeRef.current?.contentWindow === msg.source;
    const knownMethod = Object.values(Methods).includes(msg.data.method);

    return sentFromIframe && knownMethod;
  };

  private canHandleMessage = (msg: SDKMessageEvent): boolean => {
    if (!msg.data) return false;
    return Boolean(this.handlers.get(msg.data.method));
  };

  send = (data: unknown, requestId: RequestId, error = false): void => {
    const sdkVersion = getSDKVersion();
    const msg = error
      ? MessageFormatter.makeErrorResponse(requestId, data as string, sdkVersion)
      : MessageFormatter.makeResponse(requestId, data, sdkVersion);

    this.iframeRef.current?.contentWindow?.postMessage(msg, '*');
  };

  handleIncomingMessage = async (msg: SDKMessageEvent): Promise<void> => {
    const validMessage = this.isValidMessage(msg);
    const hasHandler = this.canHandleMessage(msg);

    if (validMessage && hasHandler) {
      const handler = this.handlers.get(msg.data.method);

      this.config?.onMessage?.(msg);

      try {
        if (!handler) throw new Error('Handler not found');

        const response = await handler(msg);

        // If response is not returned, it means the response will be send somewhere else
        if (response !== undefined) {
          this.send(response, msg.data.id);
        }
      } catch (err) {
        const error = err as Error;

        this.send(error.message, msg.data.id, true);
        this.config?.onError?.(error, msg.data);
      }
    }
  };

  clear = (): void => {
    window.removeEventListener('message', this.handleIncomingMessage);
    this.handlers.clear();
  };
}
