'use client';
import { useMemo, ReactNode, useEffect } from 'react';

import { SessionSubmittedModal } from '@/features/web3-modals/shared';
import { SendTransactionModal, SignMessageModal, SignTypedDataModal } from '@/features/web3-modals/dapp-iframe';
import { useDappState } from '@/features/dapp-iframe/context';

import styles from './ModalProvider.module.scss';

type DappIframeModalProviderProps = {
  children: ReactNode;
};

export function DappIframeModalProvider({ children }: DappIframeModalProviderProps) {
  const { modal, settings, mutations } = useDappState();

  const { isOpen, view } = modal;

  const componentView = useMemo(() => {
    if (!view) return null;
    const ModalComponent = {
      SendTransactionModal: <SendTransactionModal />,
      SignMessageModal: <SignMessageModal />,
      SignTypedDataModal: <SignTypedDataModal />,
      SessionSubmittedModal: (
        <SessionSubmittedModal
          isOpen={isOpen}
          vaultId={settings?.vaultId}
          closeModal={mutations?.closeModal}
          isDisplayInternalLink={false}
        />
      ),
    }[view];

    return ModalComponent;
  }, [view, isOpen, settings?.vaultId, mutations]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.withModalOpen);
    } else {
      document.body.classList.remove(styles.withModalOpen);
    }
  }, [isOpen, view]);

  return (
    <>
      {children}
      {isOpen && componentView}
    </>
  );
}
