'use client';
import { produce } from 'immer';
import {
  Permission,
  PermissionRequest,
  PermissionCaveat,
} from '@safe-global/safe-apps-sdk/dist/types/types/permissions';

import { PermissionStatus } from '@/features/dapp-iframe/types';
import { trimTrailingSlash } from '@/features/dapp-iframe/utils';

export type DappPermissions = { [origin: string]: Permission[] };

export type DappPermissionsRequest = {
  origin: string;
  requestId: string;
  request: PermissionRequest[];
};

export type DappPermissionChangeSet = { capability: string; selected: boolean }[];

export type State = {
  permissions: DappPermissions;
  permissionsRequest: DappPermissionsRequest | null;
};

export type Actions =
  | { type: 'set-permissions'; payload: DappPermissions }
  | { type: 'set-permissions-request'; payload: DappPermissionsRequest | null }
  | { type: 'update-permission'; payload: { origin: string; changeset: DappPermissionChangeSet } }
  | { type: 'remove-permissions'; payload: string }
  | { type: 'confirm-permission-request'; payload: PermissionStatus };

const USER_RESTRICTED = 'userRestricted';

export const isUserRestricted = (caveats?: PermissionCaveat[]) =>
  !!caveats?.some((caveat) => caveat.type === USER_RESTRICTED && caveat.value === true);

const updateCaveats = (permission: Permission, shouldRestrict: boolean) => {
  if (shouldRestrict && !isUserRestricted(permission.caveats)) {
    permission.caveats = [...(permission.caveats || []), { type: USER_RESTRICTED, value: true }];
  } else if (!shouldRestrict) {
    permission.caveats = permission.caveats?.filter((caveat) => caveat.type !== USER_RESTRICTED) || [];
  }
  return permission;
};

const createNewPermission = (origin: string, capability: string, isDenied: boolean): Permission => ({
  invoker: origin,
  parentCapability: capability,
  date: Date.now(),
  caveats: isDenied ? [{ type: USER_RESTRICTED, value: true }] : [],
});

export const reducer = produce((draft: State, action: Actions) => {
  switch (action.type) {
    case 'set-permissions': {
      draft.permissions = action.payload;
      break;
    }

    case 'set-permissions-request': {
      draft.permissionsRequest = action.payload;
      break;
    }

    case 'update-permission': {
      const { origin, changeset } = action.payload;
      const appUrl = trimTrailingSlash(origin);

      if (draft.permissions[appUrl]) {
        draft.permissions[appUrl] = draft.permissions[appUrl].map((permission) => {
          const change = changeset.find((c) => c.capability === permission.parentCapability);
          return change ? updateCaveats(permission, !change.selected) : permission;
        });
      }
      break;
    }

    case 'remove-permissions': {
      delete draft.permissions[trimTrailingSlash(action.payload)];
      break;
    }

    case 'confirm-permission-request': {
      if (!draft.permissionsRequest) break;

      const { origin, request } = draft.permissionsRequest;
      const updatedPermissions = [...(draft.permissions[origin] || [])];
      const isDenied = action.payload === PermissionStatus.DENIED;

      for (const requestedPermission of request) {
        const capability = Object.keys(requestedPermission)[0];
        const existingPermission = updatedPermissions.find((p) => p.parentCapability === capability);

        if (existingPermission) {
          updateCaveats(existingPermission, isDenied);
        } else {
          updatedPermissions.push(createNewPermission(origin, capability, isDenied));
        }
      }

      draft.permissions[origin] = updatedPermissions;
      break;
    }
  }
});
