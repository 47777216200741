/**
 * Enum for Bridge message methods.
 * @enum {string}
 */
export enum BridgeMessageMethod {
  /**
   * Sent when establishing the MessagePort connection between parent and iframe
   * Direction: Iframe -> Parent, Parent -> Iframe
   */
  PORT_ESTABLISHED = 'port-established',

  /**
   * Used to send GraphQL query requests from iframe to parent
   * Direction: Iframe -> Parent
   */
  GRAPHQL_QUERY = 'graphql-query',

  /**
   * Used to send GraphQL mutation requests from iframe to parent
   * Direction: Iframe -> Parent
   */
  GRAPHQL_MUTATION = 'graphql-mutation',

  /**
   * Response to GraphQL queries and mutations from parent to iframe
   * Direction: Parent -> Iframe
   */
  GRAPHQL_RESPONSE = 'graphql-response',

  /**
   * Request to switch the current vault context
   * Direction: Iframe -> Parent
   */
  SWITCH_VAULT = 'switch-vault',

  /**
   * Response to vault switch request
   * Direction: Parent -> Iframe
   */
  SWITCH_VAULT_RESPONSE = 'switch-vault-response',

  /**
   * Request to switch the current network
   * Direction: Iframe -> Parent
   */
  SWITCH_NETWORK = 'switch-network',

  /**
   * Response to network switch request
   * Direction: Parent -> Iframe
   */
  SWITCH_NETWORK_RESPONSE = 'switch-network-response',

  /**
   * Request to subscribe to a topic
   * Direction: Iframe -> Parent
   */
  SUBSCRIBE = 'subscribe',

  /**
   * Data received from a subscription
   * Direction: Parent -> Iframe
   */
  SUBSCRIPTION_DATA = 'subscription-data',

  /**
   * Error received from a subscription
   * Direction: Parent -> Iframe
   */
  SUBSCRIPTION_ERROR = 'subscription-error',

  /**
   * Response to subscribe request
   * Direction: Parent -> Iframe
   */
  SUBSCRIBE_RESPONSE = 'subscribe-response',

  /**
   * Request to unsubscribe from a topic
   * Direction: Iframe -> Parent
   */
  UNSUBSCRIBE = 'unsubscribe',

  /**
   * Response to unsubscribe request
   * Direction: Parent -> Iframe
   */
  UNSUBSCRIBE_RESPONSE = 'unsubscribe-response',

  /**
   * Request for specific permissions from iframe
   * Direction: Iframe -> Parent
   */
  REQUEST_PERMISSION = 'request-permission',

  /**
   * Response to permission requests
   * Direction: Parent -> Iframe
   */
  PERMISSION_RESPONSE = 'permission-response',

  /**
   * Request to load metadata (vault, network, user info)
   * Direction: Iframe -> Parent
   */
  LOAD_METADATA = 'load-metadata',

  /**
   * Response containing requested metadata
   * Direction: Parent -> Iframe
   */
  LOAD_METADATA_RESPONSE = 'load-metadata-response',
}

/**
 * Metadata structure representing the current context of the application.
 * This information is shared from the parent to the iframe client.
 */
export type Metadata = {
  /**
   * The ID of the currently selected vault
   * Used to identify which vault the client is interacting with
   */
  vaultId?: string;

  /**
   * The current blockchain network (e.g., 'mainnet', 'testnet')
   * Indicates which network environment the client is operating in
   */
  network?: string;

  /**
   * The ID of the authenticated user
   * Used for user-specific operations and permissions
   */
  userId?: string;

  /**
   * The ID of the user's organization
   * Used for organization-level access control and features
   */
  organizationId?: string;

  /**
   * The current environment of the application ('development', 'production', etc.)
   * Used to determine appropriate behavior based on deployment context
   */
  environment?: string;
};

/**
 * Permission capabilities for bridge communication.
 * These capabilities define what actions the iframe client can perform.
 */
export enum PermissionCapability {
  /**
   * Allows the client to execute GraphQL mutations (write operations)
   * Example: Creating, updating, or deleting data
   */
  RUN_ACTION = 'runAction',

  /**
   * Allows the client to execute GraphQL queries (read operations)
   * Example: Fetching vault data, user information
   */
  RUN_QUERY = 'runQuery',

  /**
   * Allows the client to subscribe to real-time updates
   * Example: Listening to vault changes, transaction updates
   */
  SUBSCRIBE = 'subscribe',

  /**
   * Allows the client to switch between different vaults
   * Example: Changing the active vault context
   */
  SWITCH_VAULT = 'switchVault',

  /**
   * Allows the client to change the current network
   * Example: Switching between mainnet and testnet
   */
  SWITCH_NETWORK = 'switchNetwork',

  /**
   * Allows the client to load metadata from the parent
   * Example: Getting vault ID, network info, user details
   */
  LOAD_METADATA = 'loadMetadata',
}

/**
 * Type for pending requests.
 */
export interface PendingRequest {
  /**
   * Resolves the promise with the given value.
   * @param {any} value - The value to resolve the promise with.
   */
  resolve: (value: any) => void;
  /**
   * Rejects the promise with the given reason.
   * @param {any} reason - The reason to reject the promise with.
   */
  reject: (reason?: any) => void;
}

/**
 * Interface defining the context type for the ClientBridge.
 * This interface provides all the methods and properties needed for iframe-parent communication.
 */
export interface ClientBridgeContextType {
  /** Indicates whether the bridge connection has been established and initialized */
  isInitialized: boolean;

  /** Contains the current metadata received from the parent (vault, network, user info) */
  metadata: Metadata | null;

  /** Record of granted permissions for each capability */
  permissions: Record<PermissionCapability, boolean>;

  /**
   * Executes a GraphQL query through the parent bridge
   * @param query - The GraphQL query string
   * @param variables - Variables for the GraphQL query
   * @returns Promise resolving to the query result
   */
  runQuery: (query: string, variables: any) => Promise<any>;

  /**
   * Executes a GraphQL mutation through the parent bridge
   * @param mutation - The GraphQL mutation string
   * @param variables - Variables for the GraphQL mutation
   * @returns Promise resolving to the mutation result
   */
  runMutation: (mutation: string, variables: any) => Promise<any>;

  /**
   * Subscribes to a topic through the parent bridge
   * @param subscription - The subscription string
   * @param variables - Variables for the subscription
   * @param callbacks - Callbacks for handling subscription data and errors
   * @returns Promise resolving to the subscription ID
   */
  subscribe: (subscription: string, variables: any, callbacks: SubscriptionCallbacks) => Promise<string>;

  /**
   * Unsubscribes from a topic through the parent bridge
   * @param subscriptionId - The ID of the subscription to unsubscribe from
   * @returns Promise resolving to whether the unsubscribe was successful
   */
  unsubscribe: (subscriptionId: string) => Promise<boolean>;

  /**
   * Requests the parent to switch to a different vault context
   * @param vaultId - The ID of the vault to switch to
   * @returns Promise resolving to whether the switch was successful
   */
  switchVault: (vaultId: string) => Promise<boolean>;

  /**
   * Requests the parent to switch to a different network
   * @param network - The network identifier to switch to
   * @returns Promise resolving to whether the switch was successful
   */
  switchNetwork: (network: string) => Promise<boolean>;

  /**
   * Requests specific permissions from the parent bridge
   * @param capabilities - Array of capabilities to request permission for
   * @returns Promise resolving to whether the permissions were granted
   */
  requestPermission: (capabilities: PermissionCapability[]) => Promise<boolean>;
}

/**
 * Interface defining the callbacks for subscription responses.
 */
export interface SubscriptionCallbacks {
  /**
   * Callback function for handling incoming data from the subscription.
   * @param {any} data - The data received from the subscription.
   */
  onData: (data: any) => void;

  /**
   * Callback function for handling errors from the subscription.
   * @param {any} error - The error received from the subscription.
   */
  onError?: (error: any) => void;
}
