'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';
import {
  approveEIP155Request,
  adaptToGenericMessage,
  getSignTypedDataParamsData,
  EIP155_SIGNING_METHODS,
} from '@/lib/web3-access';
import { CommunicatorMessages } from '@/features/dapp-iframe/hooks';
import { useDappState } from '@/features/dapp-iframe/context';
import { DappIframeModalWrapper } from '@/features/web3-modals/dapp-iframe';
import { MemoDropdown, PermissionDetailsCard, RequestDataCard } from '@/features/web3-modals';

import styles from './SignTypedDataModal.module.scss';

export function SignTypedDataModal() {
  const t = useTranslations('Components.Web3Modals');

  const { modal, mutations, settings } = useDappState();

  const requestId = modal.data?.requestId!;
  const requestParams = modal.data?.requestParams!;
  const sessionMetadata = modal.data?.sessionMetadata!;
  const communicator = modal.data?.communicator;

  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestParams) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);
        const response = await approveEIP155Request({
          requestParams,
          sessionMetadata,
          data: {
            vaultId: settings?.vaultId,
            account: settings?.address,
            memo,
          },
        });

        if ('error' in response) throw new Error(response.error.message);

        communicator?.send({ signature: response.result }, requestId);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
        communicator?.send(CommunicatorMessages.ERROR_TRANSACTION_MESSAGE, requestId, true);
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [
    requestParams,
    mutations,
    modal.data,
    sessionMetadata,
    settings?.vaultId,
    settings?.address,
    memo,
    communicator,
    requestId,
  ]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestParams) {
      setIsLoadingReject(true);
      try {
        communicator?.send(CommunicatorMessages.REJECT_TRANSACTION_MESSAGE, requestId, true);
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestParams, communicator, requestId, mutations]);

  // Ensure request and session metadata are defined
  if (!requestParams || !sessionMetadata) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get data
  const data = getSignTypedDataParamsData(requestParams.params);
  const customRequestParams = [settings?.address, ...requestParams.params];
  const genericMessage = adaptToGenericMessage(requestParams.method, customRequestParams, {
    chainId: requestParams.chainId,
    dappName: sessionMetadata.name,
    dappSite: sessionMetadata.url ? new URL(sessionMetadata.url).hostname : '',
    // FIXME: replace with vault name
    vaultName: settings?.vaultId,
    ...(settings.user?.name && { userName: settings.user.name }),
    memo,
  });

  const isPermissionRequest = data?.domain?.name === EIP155_SIGNING_METHODS.ETH_GET_PERMISSIONS_V1;
  let permissionScope = [];
  if (isPermissionRequest) {
    permissionScope = data?.message?.scope || [];
  }

  return (
    <DappIframeModalWrapper
      intention={t('SessionTypes.SignTypedData.intention')}
      metadata={sessionMetadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      {isPermissionRequest && permissionScope.length > 0 ? (
        <PermissionDetailsCard scope={permissionScope} />
      ) : (
        <>
          <RequestDataCard message={genericMessage} />
          <MemoDropdown memo={memo} onMemoChange={setMemo} />
        </>
      )}
    </DappIframeModalWrapper>
  );
}
