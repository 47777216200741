'use client';

import { produce } from 'immer';

import { EIP155Chain, RequestParams, SessionMetadata } from '@/lib/web3-access';
import { AppCommunicator } from '@/features/dapp-iframe/services';
import { Dapp } from '@/features/dapps/types';
import { UserInfo } from '@/lib/auth';

export type ModalData = {
  communicator: AppCommunicator | undefined;
  requestId: string;
  requestParams: RequestParams;
  sessionMetadata: SessionMetadata;
};

export type ViewType = 'SignMessageModal' | 'SignTypedDataModal' | 'SendTransactionModal' | 'SessionSubmittedModal';

type Settings = {
  currentChain: EIP155Chain;
  vaultId: string;
  user: UserInfo | null;
  address: string;
  dAppInfo: Dapp | undefined;
};

export type State = {
  isLoading: boolean;
  settings: Settings;
  chains: EIP155Chain[];
  modal: {
    isOpen: boolean;
    view?: ViewType;
    data?: ModalData;
  };
};

export type Actions =
  | { type: 'set-is-loading'; payload: boolean }
  | { type: 'initialize'; payload: State }
  | { type: 'set-current-chain'; payload: EIP155Chain }
  | { type: 'set-chains'; payload: EIP155Chain[] }
  | { type: 'open-modal'; payload: { view: State['modal']['view']; data: State['modal']['data'] } }
  | { type: 'close-modal' };

export const reducer = produce((draft: State, action: Actions) => {
  switch (action.type) {
    case 'set-is-loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'initialize': {
      return action.payload;
    }
    case 'set-current-chain': {
      draft.settings.currentChain = action.payload;
      break;
    }
    case 'set-chains': {
      draft.chains = action.payload;
      break;
    }
    case 'open-modal': {
      draft.modal.isOpen = true;
      draft.modal.view = action.payload.view;
      draft.modal.data = action.payload.data;
      break;
    }
    case 'close-modal': {
      draft.modal.isOpen = false;
      draft.modal.view = undefined;
      draft.modal.data = undefined;
      break;
    }
  }
});
