import { BaseTransaction } from '@safe-global/safe-apps-sdk';

export * from './features';

export type CustomSafeInfo = {
  safeAddress: string;
  chainId: number;
};

export type CustomBaseTransaction = BaseTransaction & {
  gas?: string;
};
