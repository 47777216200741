'use client';
import { createContext, useContext, ReactNode, useCallback, useMemo, useReducer, useEffect, useRef } from 'react';
import { Methods } from '@safe-global/safe-apps-sdk';
import { Permission, PermissionCaveat } from '@safe-global/safe-apps-sdk/dist/types/types/permissions';

import { useLocalStorage } from '@/lib/hooks/browser';
import { PermissionStatus } from '@/features/dapp-iframe/types';
import { trimTrailingSlash, DAPP_NAMESPACE } from '@/features/dapp-iframe/utils';

import {
  State,
  reducer,
  DappPermissionsRequest,
  DappPermissionChangeSet,
  isUserRestricted,
  DappPermissions,
} from './dapp-permissions-state';

type ContextType = State & {
  mutations: {
    getPermissions: (origin: string) => Permission[];
    updatePermission: (origin: string, changeset: DappPermissionChangeSet) => void;
    removePermissions: (origin: string) => void;
    setPermissionsRequest: (permissionsRequest?: DappPermissionsRequest) => void;
    checkPermissions: (request: DappPermissionsRequest) => boolean;
    confirmPermissionRequest: (result: PermissionStatus) => void;
    hasPermission: (origin: string, permission: Methods) => boolean;
    isUserRestricted: (caveats?: PermissionCaveat[]) => boolean;
  };
};

const DAPP_PERMISSIONS = `${DAPP_NAMESPACE}.DAPP_PERMISSIONS`;

const DappPermissionsContext = createContext<ContextType | undefined>(undefined);

export const DappPermissionsProvider = ({ children }: { children: ReactNode }) => {
  const [storedPermissions, setStoredPermissions] = useLocalStorage<State['permissions']>(DAPP_PERMISSIONS, {});
  const [state, dispatch] = useReducer(reducer, {
    permissions: storedPermissions,
    permissionsRequest: null,
  });

  const permissionsRef = useRef<DappPermissions>(state.permissions);

  /**
   * Update the ref when the permissions change to avoid stale closures
   */
  useEffect(() => {
    permissionsRef.current = state.permissions;
  }, [state.permissions]);

  /**
   * Update localStorage when permissions change
   */
  useEffect(() => {
    // Prevent overwriting storedPermissions with an empty object
    if (Object.keys(state.permissions).length === 0) return;
    setStoredPermissions(state.permissions);
  }, [state.permissions, setStoredPermissions]);

  const getPermissions = useCallback(
    (origin: string) => state.permissions[trimTrailingSlash(origin)] || [],
    [state.permissions],
  );

  const checkPermissions = useCallback((request: DappPermissionsRequest): boolean => {
    const originPermissions = permissionsRef.current[trimTrailingSlash(request.origin)] || [];
    return request.request.every((permissionRequest) => {
      const capability = Object.keys(permissionRequest)[0] as Methods;
      return originPermissions.some(
        (permission) => permission.parentCapability === capability && !isUserRestricted(permission.caveats),
      );
    });
  }, []);

  const hasPermission = useCallback(
    (origin: string, permission: Methods) => {
      return state.permissions[trimTrailingSlash(origin)]?.some(
        (p) => p.parentCapability === permission && !isUserRestricted(p.caveats),
      );
    },
    [state.permissions],
  );

  const mutations = useMemo(
    () => ({
      getPermissions,
      updatePermission: (origin: string, changeset: DappPermissionChangeSet) =>
        dispatch({ type: 'update-permission', payload: { origin, changeset } }),
      removePermissions: (origin: string) => dispatch({ type: 'remove-permissions', payload: origin }),
      setPermissionsRequest: (request?: DappPermissionsRequest) =>
        dispatch({ type: 'set-permissions-request', payload: request || null }),
      checkPermissions,
      confirmPermissionRequest: (result: PermissionStatus) =>
        dispatch({ type: 'confirm-permission-request', payload: result }),
      hasPermission,
      isUserRestricted,
    }),
    [getPermissions, checkPermissions, hasPermission],
  );

  const value = useMemo(
    () => ({
      ...state,
      mutations,
    }),
    [state, mutations],
  );

  return <DappPermissionsContext.Provider value={value}>{children}</DappPermissionsContext.Provider>;
};

export const useDappPermissions = (): ContextType => {
  const context = useContext(DappPermissionsContext);
  if (!context) {
    throw new Error('useDappPermissions must be used within a DappPermissionsProvider');
  }
  return context;
};
